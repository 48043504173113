import $ from 'jquery';

if ( $('.single-campaign').length ) {
  var asideTerms = $('aside .flex-taxonomy');
  asideTerms.each(function() {
    console.log($(this));
    var termsEl = $(this).find('.terms');

    var originalHeigth = termsEl.outerHeight();
    var closedHeight = 190;
    console.log(closedHeight, originalHeigth);
    termsEl.css("max-height", closedHeight);

    var hiddenElements = $(this).find('.term.hidden');
    var toggleButton = $(this).find('.show-more');


    var active = false;

    toggleButton.on('click', function() {
      $(this).toggleClass('active');
      hiddenElements.each(function() {
        $(this).toggleClass('hidden');
      })
      if(!active) {
        termsEl.css("max-height", originalHeigth);
      } else {
        termsEl.css("max-height", closedHeight);
      }
      active = !active;
    })
  });
}