import $ from 'jquery';

const targets = $('.flex-module.list_v2 .list-border .button-primary');
const o = new IntersectionObserver(entry, {threshold: 0, rootMargin: "-50% 0% -50% 0%"});
if(targets.length) {
  targets.each(function () {
    o.observe(this);
  })
}

function entry(entries) {
  entries.forEach(entry => {
    if(entry.isIntersecting) {
      $(entry.target).addClass('active');
      setTimeout(function() {
        $(entry.target).removeClass('active');
      }, 700);
    }
  })
}