import $ from 'jquery';

$(function() {
  const herobanner = $('.hero-banner');
  if(herobanner.length) {
    const headlines = $('.hero-banner span[data-size]');
    headlines.each(function() {
        const dataSize = $(this).attr('data-size');
        $(this).css('font-size', `${dataSize}em`);
    });
  }
})