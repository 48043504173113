import $ from 'jquery';

if($('#site-header').length) {
   const burgerIcon = $('#burger-menu-icon');
   const burgerNav = $('#burger-nav');
   const closeIcon  = $('.close-btn', burgerNav);

   burgerIcon.on('click', (e) => {
      burgerIcon.toggleClass('active');
      burgerNav.toggleClass('active');
   });
   closeIcon.on('click', (e) => {
      burgerIcon.removeClass('active');
      burgerNav.removeClass('active');
   });

   const burgerMenu = $('#burger-nav');
   const itemsWithMenu = $('.menu-item-has-children', burgerMenu);
   if( itemsWithMenu.length ) {
      itemsWithMenu.each( function() {
         const item = $(this);
         const submenu = $('> .menu', item);
         const a = $('> a', item);
         submenu.hide();
         a.click(function(e) {
            e.preventDefault();
            item.toggleClass('active');
            submenu.toggleClass('active');
            submenu.slideToggle({
               duration: 150,
            });
         });
      });
   }

   // Ajax search
   const searchBox = $('.search-field', '#site-header');
   const icon = $('.search-icon', '#site-header');
   icon.on('click', function() {
     $(this).toggleClass('active');
     searchBox.toggleClass('active');
   })
   

}
