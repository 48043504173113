import $ from 'jquery';

if($('.show-hidden-taxonomies').length) {
  const buttons = $('.show-hidden-taxonomies');
  buttons.each(function() {
    const hiddenItems = $('.taxonomy-icon.hidden', $(this).closest('.taxonomy-items'));
    let buttonTexts = $('p', $(this));
    $(this).on('click', function(e) {
      buttonTexts.each(function() {
        $(this).toggleClass('active');
      })
      hiddenItems.each(function() {
        $(this).toggleClass('hidden');
      })
    })
  });
}