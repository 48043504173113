function playPauseVideo() {
  let videos = document.querySelectorAll("video[autoplay]");

  // videos.forEach((video) => {
  //     // We can only control playback without insteraction if video is mute
  //     video.muted = true;
  //     // Play is a promise so we need to check we have it
  //     let playPromise = video.play();
  //     if (playPromise !== undefined) {
  //         playPromise.then((_) => {
  //             let observer = new IntersectionObserver(
  //                 (entries) => {
  //                     entries.forEach((entry) => {
  //                         if (
  //                             entry.intersectionRatio !== 1 &&
  //                             !video.paused
  //                         ) {
  //                             video.pause();
  //                         } else if (video.paused) {
  //                             video.play();
  //                         }
  //                     });
  //                 },
  //                 { threshold: 0.1 }
  //             );
  //             observer.observe(video);
  //         });
  //     }
  // });

  document.addEventListener("DOMContentLoaded", function() {
    var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));
  
    if ("IntersectionObserver" in window) {
      var lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(video) {
          if (video.isIntersecting) {
            if(video.target.getAttribute('src')) {
              
            } else {
              let src = video.target.getAttribute('data-src');
              video.target.setAttribute('src', src);
              video.target.load();
              video.target.classList.remove("lazy");
              //lazyVideoObserver.unobserve(video.target);
            }
          }
        });
      });
  
      lazyVideos.forEach(function(lazyVideo) {
        lazyVideoObserver.observe(lazyVideo);
      });
    }
  });
}

// And you would kick this off where appropriate with:
playPauseVideo();
