import $ from 'jquery';

const accordions = $('.accordion');

if( accordions.length ) {
   accordions.each(function() {
      const accordion = $(this);
      const items = $('.item', accordion);
      items.each(function() {
         const item = $(this);
         const title = $('> .title', item);
         const content = $('.content', item);
         content.hide();
         title.click(function() {
            item.toggleClass('active');
            content.slideToggle({
               duration: 250
            });
         });
      });
   });
}