/* Import SCSS files */
import '../sass/main.scss';

/* Import JS files here */
import './snippets/linkParams';
import './snippets/global';
import './snippets/header';
import './snippets/campaign';
import './snippets/rating';
import './snippets/quick-navigation';
import './snippets/redirect';
import './snippets/single-casino';
import './snippets/videos';
import './snippets/numberAnimation';
import './snippets/list';
import './snippets/games';