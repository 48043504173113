import $ from 'jquery';

let ratingProgress = $('.rating-progress-container');
const colors = {
   95: '#79CB00',
   90: '#AACB00',
   85: '#D6D100',
   0: '#D7C800'
}

if( ratingProgress.length ) {
   ratingProgress.each(function() {
      const backgroundColor = $(this).attr('data-background') ? $(this).attr('data-background') : 'white';
      const toColor = $(this).attr('data-to-color');
      const value = $(this).attr('data-value');
      const circularProgress = $('.circular-progress', $(this));
      const backgroundElement = $('.background', $(this));
      let color;

      Object.keys(colors).forEach(key => {
         if(value >= key) {
            color = colors[key];
         }
       });

      if(toColor) {
        color = toColor;
      }
      // backgroundElement.css('background', backgroundColor);

      circularProgress.css('background', `conic-gradient(
         ${color} ${value * 3.6}deg,
         ${backgroundColor} ${value * 3.6}deg
      )`);

   });
}



function animateRating(el) {
  let progressBar = el;
  let valueContainer = progressBar.querySelector(".value-container .rating-text");

  let progressValue = 0;
  let progressEndValue = el.getAttribute('data-value');
  let speed = 15;

  let progress = setInterval(() => {
    progressValue++;
    valueContainer.textContent = `${progressValue}`;
    progressBar.style.background = `conic-gradient(
        #5FBA58 ${progressValue * 3.6}deg,
        #EDEDED ${progressValue * 3.6}deg
    )`;
    if (progressValue == progressEndValue) {
      clearInterval(progress);
    }
  }, speed);
}


let observer = new IntersectionObserver(onEntry, options);

let options = {
  threshold: [1]
};

function onEntry(entries) {
  entries.forEach(entry => {
    if(entry.intersectionRatio > 0) {
      animateRating(entry.target);
      observer.unobserve(entry.target);
    }
  })
}

// Run the animation on all elements with a class of ‘countup’
const elements = document.querySelectorAll( '.circular-progress.new' );

elements.forEach(el => {
  observer.observe(el);
});


// SVG Rating
// ----------------------
const targets = $('.progress.circle');
const o = new IntersectionObserver(entry, {threshold: 0.5});

if(targets.length) {
  targets.each(function () {
    o.observe(this);
    $(this).find('.number').text = '0';
  })
}

function entry(entries) {
  entries.forEach(entry => {
    if(entry.intersectionRatio > 0) {
      animate(entry.target);
      o.unobserve(entry.target);
    }
  })
}

function animate(entry) {
  let numberEl = $(entry).find('.number');
  let progressEl = $(entry).find('.progress');
  let rating = $(entry).attr('data-rating');
  
  numberEl.text = rating;
  progressEl.css('stroke-dashoffset', (300 - 3 * rating));
}