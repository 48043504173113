import $ from 'jquery';

console.log("works")

$(function() {
  const gamesSections = $('.flex-module.games');

  if ( gamesSections.length > 0 ) {
    gamesSections.each(function() {
      let gamesSlider = $(this).find('.swiper')[0];

      new Swiper(gamesSlider, {
        slidesPerView: 1,
        spaceBetween: 16,
        autoplay: {
          delay: 1500,
        },
        speed: 300,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
        },
        breakpoints: {
          600: {
            slidesPerView: 2
          },
          800: {
            slidesPerView: 3
          },
        }
      });

    })
  }
})