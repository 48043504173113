import $ from 'jquery';


if ($('body').hasClass('single-link')) {
  const countDown = $('.countdown');
  let value = 3;

  countDown.text(value);

  setInterval(countDownFunc, 1000);

  function countDownFunc() {
    if(value > 0) {
      value--;
      countDown.text(value);
    }
  }
}